import { Component, Input } from '@angular/core';
import { SafeHtmlPipe } from '../../helpers/pipes/safehtml.pipe';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-common-tooltip',
  standalone: true,
  imports: [SafeHtmlPipe, FormsModule],
  template: `<div
    class="p-2 border rounded-md text-nowrap shadow-md"
    [style.background]="bg"
  >
    {{ text }}
  </div>`,
})
export class CommonTooltipComponent {
  @Input() text = '';
  @Input() bg = '';
}
